@import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&family=Philosopher:wght@400;700&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: "PT Sans", sans-serif; */

}

body>iframe {
  display: none;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

a {
  text-decoration: none;
  transition: all 0.2s;
  /* color: #000; */
}

button {
  transition: all 0.2s;
}

button:hover,
a:hover {
  /*  opacity: 0.75; */
  opacity: 1;
}

.btn:focus,
.form-control:focus {
  box-shadow: none;
}

button:focus {
  outline: 0px;
}

.form-control {
  /* border: 1px solid rgb(232, 74, 86) !important; */
  font-family: "Roboto Slab", serif;
  border: 1px solid #a024b7;
}

.form-control:focus {
  border: 1px solid #a024b7;
}

.category_Name span {
  color: #a024b7;
  /* color: rgb(232, 74, 86); */
  font-family: "Roboto Slab", serif;
}

.category_Name {
  /* font-weight: 600; */
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  color: #0a0e17;
  margin: 0 auto;
  font-family: "Roboto Slab", serif;
}

.relative {
  position: relative;
}

._close_btn {
  position: absolute;
  border: none;
  width: 25px;
  height: 25px;
  right: 0;
  top: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*--------------Testimonial Review---------------------*/
.testiMonialUserprofile {
  display: flex;
  align-items: center;
}

.testiMonialUserprofile img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}

.testiMonialUserprofile h1 {
  font-size: 30px;
  line-height: 2.5rem;
}

/********************** General ************************/
.navbar-dark .navbar-toggler-icon {
  background: url("../src/assets/toggler.svg");
}

.main_loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 101;
  display: grid;
  place-items: center;
  background: #fff;
}

.main_loader>img {
  max-height: 100px;
}

.PrimaryBtn {
  background: #a024b7;
  margin: 0 auto;
  color: #fff;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  /* min-width: 120px; */
  width: 100%;
  max-width: 123px;
  line-height: 30px;
  border: 1px solid #a024b7;
  transition: .6s;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PrimaryBtn:hover {
  /* color: #a024b7;
  background-color: transparent;
  border: 1px solid #a024b7; */
  color: #fff;
  text-decoration: none;
}

.bg_lineCover {
  border: 10px solid transparent;
  border-image: url("../src/assets/provider_bg.svg") 10 stretch;
}

.cartBtn {
  background: transparent;
  color: #343a40 !important;
  margin: 0 5px;
}

.cart_box_right {
  background: #fff;
  box-shadow: 0 20px 20px rgb(0 0 0 / 10%);
  border-radius: 5px;
}

.cart_box_right h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
}

.list-group-item {
  border-bottom: none;
}

.modal-body .choose_file {
  background: #a3aaba;
  border-radius: 5px;
  font-style: normal;
  /* font-weight: bold; */
  font-size: 15px;
  /* padding: 5px 10px; */
  color: #ffffff;
  width: 140px;
  height: 48px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.modal-body .choose_file .input_file {
  position: absolute;
  padding: 8px;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.secTionHeading {
  /* font-family: "Philosopher", sans-serif;
  font-size: 52px;
  position: relative;
  padding-bottom: 40px; */

  font-family: "Roboto Slab", serif !important;
  margin-top: 6px;
  font-size: 32px;
  font-weight: 400;
  color: #fff;
}

/* .secTionHeading.DesignBtm::after {
  content: url("./Default/Assets/Images/underSectionHeading.png");
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%);
} */
.secTionHeading.designBtm2::after {
  /* content: url("./Default/Assets/Images/underSectionHeading2.png");
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%); */
}

.EditProfilePic .Single_close,
.ProfDelete .Single_close,
.Rating .Single_close {
  position: absolute;
  top: 5px;
  right: 6px;
}

/********************* Navbar *************************/

.Navbar {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 99;
  padding-top: 10px;
  transition: 0.3s ease-in-out;
  background-color: #fff;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.Navbar.Sticky {
  position: fixed;
  position: sticky;
  background: rgba(79, 25, 99, 0.8);
  background: rgba(79, 25, 99, 1);
  transition: 0.3s ease-in-out;
}

.Navbar .dropdown-toggle::after {
  position: relative;
  top: 5px;
  left: 3px;
}

.Navbar .nav-item {
  margin: 0px 5px;
  text-transform: uppercase;
}

.nav-link {
  /* color: #fff; */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  position: relative;
  color: #000;
  text-transform: capitalize;
}

.nav-link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  /* background-color: rgb(232, 74, 86); */
  background-color: #a024b7;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  transition: all .3s ease-in-out;
}

.nav-link:hover {
  color: #000;
}

.nav-link:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.Navbar .dropdown-toggle::after {
  border-top: 0.3em solid #fff;
}

.Navbar .dropdown-menu {
  min-width: 16rem;
  min-height: 13rem;
  right: -15px;
  left: auto;
  margin-top: 22px;
  background: #f4d1f6;
  border: 1px solid #b214b2;
  padding: 20px 0px;
}

.Navbar .dropdown-menu::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  top: -61px;
  right: 33px;
  border: 30px solid #f4d1f6;
  border-color: transparent transparent #f4d1f6 transparent;
}

.Navbar .dropdown-menu .dropdown-item,
.Navbar .dropdown-menu h5 {
  color: #450b5a;
  font-size: 24px;
}

.Navbar .dropdown-menu .dropdown-item {
  font-size: 22px;
}

.Navbar .SearchBar {
  /*  width: 100%;  */
  width: 80%;
  /*  margin-right: 15px; */
  margin: 0 auto;
  position: relative;
}

.Navbar .SearchBox {
  border-radius: 10px 0px 0px 10px;
  background-color: #cccccc;
  border-right-color: #cccccc;
  font-size: 18px;
  padding-left: 25px;
  font-weight: 600;
}

.Navbar .CrossBtn {
  background-color: #cccccc;
}

.Navbar .CrossBtn button {
  border-radius: 0px;
  color: #a024b7;
  font-size: 24px;
  padding: 2px 10px 1px 10px;
}

.Navbar .SearchIcon {
  background-color: #a024b7;
  border-radius: 0px 10px 10px 0px;
}

.Navbar .SearchIcon button {
  color: #fff;
}

.Navbar .SearchDrop {
  background-color: #d7d7d7;
  padding: 16px;
  position: absolute;
  width: 100%;
  box-shadow: 0px 0px 5px #fff;
  margin-top: 8px;
  z-index: 3;
}

.Navbar .SearchDrop .NavScroller {
  max-height: 250px;
  overflow-y: auto;
}

.Navbar .SearchDrop li {
  padding: 8px 5px;
  font-size: 18px;
  font-weight: 600;
  color: #373737;
}

.Navbar .SearchDrop li span {
  color: #aaa9a9;
  font-weight: 500;
}

.Navbar .navbar-brand,
.Navbar_OnScroll .navbar-brand {
  font-size: 36px;
  font-family: "Philosopher", sans-serif;
  color: #fff;
  /* font-weight: 600; */
}

/********************* Navbar Ends *************************/
.carousel-inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-control-next-icon {
  background-image: url(../src/assets/righticon.png);
  height: 40px;
  width: 40px;
}

.carousel-control-prev-icon {
  background-image: url(../src/assets/previcon.png);
  height: 40px;
  width: 40px;
}

/* .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 40%;
  left: 18%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
} */

.carousel-caption h3,
.carousel-caption p {
  color: #000;
}

.carousel-caption h3 {
  font-weight: normal;
  font-size: 44px;
  line-height: 1.2;
  text-align: left;
  color: #000;
  font-family: 'Alegreya', serif;
}

.carousel-caption p {
  font-style: normal;
  font-weight: 400;
  font-size: 20.8px;
  line-height: 30px;
  text-align: left;
  color: #000;
  font-family: "Roboto", sans-serif;
}

.carousel-inner {
  height: 80vh;
}

.carousel-indicators {
  max-width: 100px;
  margin: 0 auto 40px;
  justify-content: space-around;
}

.carousel-indicators li {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #a024b7;
  border-bottom: transparent;
  border-top: transparent;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  top: 35%;
  bottom: 0;
  left: -20%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: left;
  max-width: 600px;
  margin: 0 auto 40px;
}

.carousel-item {
  height: 100%;
}

.shop_btn {
  border: 2px solid #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  border-radius: 10px;
  min-width: 170px;
  background-color: #a024b7;
  text-align: center;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  padding: 8px 30px;
}

.shop_btn:hover {
  background: #000;
  color: #fff;
}

/********************* Jumbotron *********************/
.blog .Jumbotron {
  /*  min-height: 510px; */
  min-height: 300px;
  background-size: cover;
  background-position: bottom;
  display: flex;
  align-items: center;
  color: #fff;
  margin-bottom: 1rem;
}

.blogSinglePage .Jumbotron {
  /*  min-height: 510px; */
  min-height: 300px;
  background-size: cover;
  background-position: bottom;
  display: flex;
  align-items: center;
  color: #fff;
  margin-bottom: 1rem;
}

.Jumbotron {
  /* min-height: 510px; */
  background-size: cover;
  display: flex;
  align-items: center;
  color: #fff;
  margin-bottom: 1rem;
  padding: 3rem 0;
}

.Jumbotron h3 {
  font-size: 48px;
  /* font-family: "Philosopher", sans-serif; */
  max-width: 140px;
  border-bottom: 2px solid #a024b7;
  /* border-bottom: 2px solid rgb(232, 74, 86); */
  margin: auto;
  font-size: 16px;
  line-height: 30px;
  color: #0a0e17;
}

.Jumbotron h4 {
  font-size: 30px;
  font-family: "Philosopher", sans-serif;
}

/********************* Home **************************/

.Home .JumbotronHome {
  padding-top: 120px;
  background-image: url("./Default/Assets/Images/HomeBg1.png");
  /*  background-size: 100% 97%; */
  background-size: cover;
  background-position: top;
  /*  min-height: 600px; */
  min-height: 1000px;
  color: white;
}

.JumbotronHome .PrimaryBtn {
  font-size: 18px;
  text-transform: uppercase;
}

.JumbotronHome h1 {
  font-size: 52px;
  font-family: "Philosopher", sans-serif;
}

.Home .JumbotronHome p {
  font-size: 22px;
  margin-top: 2rem;
}

.JumboNavBtn {
  background: linear-gradient(to right, #8706a1, rgba(18, 7, 20, 0.8));
  color: white;
  border-radius: 20px;
  padding: 8px 30px;
  border: 0px;
  min-width: 250px;
  font-size: 18px;
}

.JumboNavBtn:hover {
  color: #fff;
}

.SpiritualConnect {
  position: relative;
  z-index: 2;
  /* margin-top: -110px; */
}

/* .SpiritualConnect .PrimaryBtn,
.About .PrimaryBtn,
.Articles .PrimaryBtn {
  font-size: 20px;
  text-transform: uppercase;
} */

.SpiritualConnect h4 {
  font-size: 24px;
  font-family: "Philosopher", sans-serif;
}

.SpiritualConnect p {
  font-family: "Philosopher", sans-serif;
}

.SpiritualConnect img {
  height: 102px;
}

.contact_div_index {
  background: #1d3557;
  padding: 2.3rem 0;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.contact_div_index_text_div p {
  color: white;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
}

.contact_div_index_btn_div .contact_div_index_btn {
  /* background-color: #e84a56; */
  background-color: #a024b7;
  color: white;
  transition: all 0.3s;
}

.Pink_layout {
  position: relative;
}

.about_ban_img {
  position: absolute;
  top: 15%;
  bottom: 0;
  right: 0;
  left: 8%;
}

.about_ban_head h4 {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 30px !important;
  line-height: 30px !important;
  color: #0a0e17 !important;
  font-family: "Roboto", sans-serif !important;
}

.about_ban_img img {
  height: 300px;
  object-fit: cover;

}

.About {
  background-image: url("./Default/Assets/Images/HomeBg2.png");
  background-position: 41% 4%;
  background-size: 100% 100%;
  padding: 20px 0px 20px 0px;
  position: relative;
  bottom: 70px;
  /*  min-height: 750px; */
  min-height: 900px;
}

.About h5,
.About p {
  font-size: 22px;
}

.About h3 {
  font-family: "Philosopher", sans-serif;
  font-size: 34px;
}

/********************* Service Provider ********************/
.ServiceProvider {
  position: relative;
  /* bottom: 60px; */
  margin-top: 20px;
  z-index: 2;
  /* margin-top: -60px; */
  background: #4f1963;
  padding: 1rem 0;
}

.ServiceProvider p {
  color: #fff;
  text-transform: capitalize;
}

.providerCon h4 {
  /* font: bold 24px "Philosopher", sans-serif;
  min-height: 56px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */

  min-height: 56px;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  color: #a024b7;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-family: "Roboto", sans-serif;

}

.providerCon p {
  margin-bottom: 0;
  min-height: 42px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.providerCon h5 {
  font-size: 18px;
  min-height: 66px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.4;
  margin-bottom: 0;
  text-align: center;
  color: #0a0e17;

}

.providerCon {
  /* border: 2px solid #a024b7;
  padding: 20px;
 
  box-shadow: 2px 3px 5px #cccccc; */

  background: #fff;
  box-shadow: 0 0 25px rgb(10 14 23 / 15%);
  border-radius: 6px;
  border: none;
  width: 100%;
  height: 100%;
  max-height: 460px;
  min-height: 250px;
}

.providerCon img {
  height: 105px;
}

.ServiceProvider .slick-prev,
.ServiceProvider .slick-next {
  width: 50px;
  height: 85px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.ServiceProvider .slick-prev::before,
.ServiceProvider .slick-next::before {
  content: "";
}

/* .ServiceProvider .slick-prev {
  background-image: url("./Default/Assets/Images/ProviderSlider/leftArrow.png") !important;
  z-index: 3;
  left: 0px;
} */
/* .ServiceProvider .slick-next {
  background-image: url("./Default/Assets/Images/ProviderSlider/rightArrow.png") !important;
  right: 0px;
} */
.providerCon a {
  color: #a024b7;
}

/********************* Service Provider Ends ********************/

/********************* Quotation ********************/

.Quotation {
  background-image: url("./Default/Assets/Images/HomeBg3.png");
  padding: 290px 0px 70px 0px;
  padding: 160px 0px 70px 0px;
  min-height: 700px;
  background-position: 30% 4%;
  position: relative;
  bottom: 120px;
  z-index: 1;
  background-size: cover;
}

.Quotation .Quotes {
  border: 2px solid #5f206a;
  display: flex;
  padding: 20px;
}

.Quotation .imgCon {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1px solid #5f206a;
  margin: 0px auto;
  position: relative;
  bottom: 35px;
}

.Quotation .imgCon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.Quotation .Quotes p {
  min-height: 160px;
}

.Quotation h3 {
  font-size: 30px;
  color: #a024b7;
  font-weight: 600;
}

.Quotation h5 {
  font-size: 18px;
}

.Quotation .slick-dots li {
  width: 30px;
}

.Quotation .slick-dots li button {
  width: 30px;
  height: 10px;
  border-radius: 5px;
  background: #4f4950;
}

.Quotation .slick-dots li.slick-active button {
  background: #a024b7;
}

.Quotation .slick-dots li button:before {
  content: "";
}

.Quotation .slick-prev,
.Quotation .slick-next {
  width: 50px;
  height: 80px;
  top: 25%;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.Quotation .slick-prev::before,
.Quotation .slick-next::before {
  content: "";
}

.Quotation .slick-prev {
  background-image: url("./Default/Assets/Images/ProviderSlider/leftArrow.png") !important;
  z-index: 3;
  left: -5%;
}

.Quotation .slick-next {
  background-image: url("./Default/Assets/Images/ProviderSlider/rightArrow.png") !important;
  right: -5%;
}

/*-------Service Details-------*/
.detail_pic {
  /* min-height: 400px; */
  height: 350px;
  object-fit: contain;
}

.category-listingImage {
  width: 100px;
  height: 120px;
}

/***************** Article *****************/
.heading {
  display: flex;
  justify-content: space-between;
}

.Articles {
  position: relative;
  padding: 1rem 0;
  background: #4f1963;
}

.Articles img {
  /* height: 280px;
  margin-bottom: 20px;
  max-width: 100%; */

  width: 100%;
  border-radius: 64px 64px 0 0;
  height: 208px;
  object-fit: cover;
  object-position: top;
}

.Articles .date {
  color: #4f4b4b;
  font-family: "Philosopher", sans-serif;
  font-size: 16px;
}

.Articles h4 {
  font-family: "Philosopher", sans-serif;
  font-size: 26px;
  min-height: 62px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;


  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  color: #0a0e17;
  min-height: 60px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: "Roboto", sans-serif;
}

.Articles p {
  font-size: 14px;
}

.Articles p._details {
  min-height: 62px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.customBox {
  background: #fff;
  box-shadow: 0 0 20px rgb(6 7 9 / 34%);
  border-radius: 64px 64px 20px 20px;
  width: 100%;
  margin: auto;
  height: auto;
  border: none;
  padding: 1px;
}



/******************** Subscribe *******************/

/* .Subscribe {
  background-image: url("./Default/Assets/Images/SubscribeBg.png");
  padding: 250px 0px 30px 0px;
  background-position: 38% 51%;
  background-size: 100% 100%;
  margin-top: -294px;
} */
.Subscribe .CardTop {
  border: 1px solid #c1a4cc;
  padding: 15px;
  background: linear-gradient(#a669ba, #a834b0);
  color: white;
  border-radius: 10px;
}

.CardTop h3 {
  font-size: 36px;
  font-weight: bold;
}

.CardBody {
  background: rgba(168, 52, 176, 0.36);
  padding: 20px;
  border-radius: 0px 0px 10px 10px;
  margin-top: -5px;
}

.CardBody h1 {
  font-size: 60px;
}

.CardBody p {
  font-size: 14px;
  word-wrap: break-word;
}

.SubscribeBtn {
  border: 1px solid rgb(255, 252, 252);
  background-color: rgb(160, 36, 183);
  color: #fff;
  padding: 15px 40px;
  border-radius: 40px;
  font-size: 20px;
  text-transform: uppercase;
}

/************************ Home ends ***************************/

/************************ Blogs ***************************/
.blogContent {
  /* background-image: url("./Default/Assets/Images/BlogBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; */
}

.ReadMore {
  background: #a024b7;
  padding: 6px 40px;
  border-radius: 20px;
  color: #fff;
  margin: 15px auto;
}

.ReadMore:hover {
  color: #fff;
}

.blog .card {
  /* box-shadow: 0px 0px 9px 1px #c4c4c4; */
  box-shadow: 0 0 15px rgb(10 14 23 / 10%);
  border-radius: 10px;
  margin-bottom: 50px !important;
  display: block;
  /* flex-direction: row; */
}

.blog .Cardtop {
  position: relative;
}

.service_head h3 {
  font-family: "Roboto Slab", serif;
  margin-top: 6px;
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 40px !important;
}

#serviceshome {
  padding: 2rem 0;
}

.blog .Cardtop img.card-img-top {
  /* height: 330px;
  width: 250px;
  object-fit: cover;
  object-position: top; */
  aspect-ratio: 16/12;
}

.Cardtop .Date {
  /* position: absolute;
  top: 274px;
  left: 0px; */
  width: 100%;
  display: inline-block;
  padding: 10px 30px;
  /* background-color: #642a81; */
  background-color: transparent;
  color: #642a81;
  /* font-family: "Philosopher", sans-serif; */
  font-family: "Roboto Slab", serif;
  font-size: 24px;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}

.blog p {
  color: #3e3c3c;
}

.blog .card-text {
  /* font-family: "Philosopher", sans-serif; */
  margin-bottom: 10px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;


  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(10, 14, 23, .7);
  min-height: 48px;
  height: 52px;
}

.blog .card-title {
  /* font-size: 24px;
  position: relative; */

  font-weight: 600;
  font-size: 22px;
  min-height: 52px;
  color: #0a0e17;
}

.blog .card-title::before {
  /* content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 11px solid #642a81;
  position: absolute;
  bottom: 0; */
}

.blog .card-title::after {
  /* content: "";
  width: 55px;
  height: 2px;
  border: 2px solid #642a81;
  position: absolute;
  bottom: 8px;
  left: 22px; */
}

.blog .BtnSearch,
.ServiceList .BtnSearch {
  background-color: #75429c;
  color: #fff;
  border-radius: 0px;
}

.blog .categories ul {
  border: 1px solid #75429c;
}

.blog .categories ul li {
  padding: 10px 15px;
  border-bottom: 1px solid #75429c;
  color: #574d4d;
  background-color: #fff;
  cursor: pointer;
}

.blog .categories ul li:hover {
  background-color: #75429c;
  color: #fff;
}

.blog .categories ul li:first-child {
  background-color: #75429c;
  font-size: 20px;
  color: #fff;
  font-family: "Philosopher", sans-serif;
}

.blog .categories ul li:last-child {
  border-bottom: 0px;
}

.single_blog img {
  height: 250px;
  object-fit: cover;
  object-position: center;
}

.single_blog .card-text {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.pagination {
  justify-content: center;
}

.page-link {
  margin: 0px 5px;
  /* background: #75429c 0% 0% no-repeat padding-box; */
  background: transparent;
  color: #000;
  /* border: 2px solid rgb(232, 74, 86); */
  border-radius: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.page-item:last-child .page-link {
  /* margin-right: 0;
  border-radius: 50%;
  background-color: #222222;
  color: #fff;
  margin-left: 15px; */


  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item:first-child .page-link {
  /* margin-left: 0;
  margin-right: 15px;
  border-radius: 50%;
  background-color: #222222;
  color: #fff; */

  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;

}

.page-item.active .page-link,
.page-link:hover {
  /* border: 2px solid rgb(232, 74, 86); */
  /* background: #75429c 0% 0% no-repeat padding-box; */
  background-color: #e9ecef;
  color: #000;
}

.commentSection h2 {
  font-size: 48px;
  color: #621f6f;
  font-family: "Philosopher", sans-serif;
  position: relative;
  width: fit-content;
  margin: 0 auto;
  padding-bottom: 50px;
}

.commentSection h2::after {
  content: url("./Default/Assets/Images/Blog/BelowCommentSection.png");
  position: absolute;
  top: 42px;
  right: 23%;
}

.commentSection label {
  font-size: 18px;
  font-weight: bold;
}

.commentSection .form-control {
  /*  background: transparent; */
  background: #fff;
  border-radius: 0px;
  border-color: #000;
}

.BlogSlider.blog p {
  font-size: 12px;
}

.BlogSlider.blog .card-title {
  font-size: 20px;
}

.BlogSlider .slick-prev,
.BlogSlider .slick-next {
  width: 60px;
  height: 110px;
}

.BlogSlider .slick-prev::before,
.BlogSlider .slick-next::before {
  content: "";
}

.BlogSlider .slick-prev {
  background: url("./Default/Assets/Images/Blog/leftArrowBlog.png") no-repeat center/contain !important;
  z-index: 3;
  left: -46px;
}

.BlogSlider .slick-next {
  background: url("./Default/Assets/Images/Blog/rightArrowBlog.png") no-repeat center/contain !important;
  right: -48px;
}

/************************* Blog Ends ***************************/

/************************* About Us ****************************/
#title-about {
  padding: 100px 0 50px;
}

.AboutUs h4 {
  /* color: #7e1193; */
  color: #a024b7;
  font-family: "Philosopher", sans-serif;
  font-size: 24px;
  font-weight: bold;
}

.AboutUs p {
  /* line-height: 1.8; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(10, 14, 23, .7);
}

p.title-wrapper {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 38px;
  color: #a024b7;
}

.AboutUs .countBox {
  width: 20%;
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #fff;
}

.AboutUs .counterCircle {
  border: 1px solid #8652ad;
  border-radius: 50%;
  height: 65px;
  width: 65px;
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
  color: #ee8722;
}

.countBox p {
  text-transform: uppercase;
}

.Aboutfaq {
  /* background-image: url("./Default/Assets/Images/AboutUs/FAQbgImg.png");
  background-size: cover; */
}

.Aboutfaq h5 {
  font-size: 24px;
  font-weight: bold;
  /* font-family: "Philosopher", sans-serif; */
  font-family: "Roboto", sans-serif;
}

.Aboutfaq .ans_text p {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 0px;
  /* background-color: #75429c; */
  background-color: transparent;
  color: #000;
  border-bottom: 1px solid #dee2e6;
  font-weight: 600;
}

.fa-plus,
.fa-minus {
  /* color: rgb(232, 74, 86); */
  color: #a024b7;
}

.Aboutfaq .faqbg {
  background-color: #fff;
  border-radius: 0px 0px 8px 8px;
  padding: 15px;
}

.Aboutfaq .faqbg h6 {
  color: #686b73;
  font-size: 16px;
}

.AboutVideo {
  height: 475px;
  background-image: url("./Default/Assets/Images/Blog/blogImg1.png");
  background-size: cover;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AboutVideo::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  background: linear-gradient(transparent, rgba(18, 7, 20, 0.6) 55%);
}

.video_part {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.534);
}

.video_part video {
  width: 100%;
}

button.close_btn {
  position: absolute;
  right: 0;
  z-index: 1;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  background: #a024b7;
}

.playBtn {
  font-size: 71px;
  border-radius: 50%;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}

.VideoContent {
  position: absolute;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/************************* About Us End ****************************/

/************************* Error ****************************/
.ErrorJumbo {
  min-height: 1000px;
  background-image: url("./Default/Assets/Images/Error/errorJumboBg.png");
  background-size: cover;
  background-position: bottom;
}

.ErrorJumbo h1 {
  font-size: 136px;
  font-family: "Philosopher", sans-serif;
  text-align: center;
  color: #fff;
}

.centerText {
  color: #450b5a;
}

.centerText h2 {
  font-family: "Philosopher", sans-serif;
  font-size: 50px;
  font-weight: 600;
}

.centerText h4 {
  font-weight: 600;
  font-size: 24px;
}

.ErrorFooter {
  min-height: 295px;
  background-image: url("./Default/Assets/Images/Error/ErrorFooterBg.png");
  background-size: cover;
  background-position: bottom;
  display: flex;
  align-items: flex-end;
}

/************************* Error End ****************************/
.FavouriteShop {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 16px 0px rgba(167, 167, 167, 0.75);
  padding: 20px;
}

.FavouriteShop h3 {
  font-size: 36px;
  font-family: "Philosopher", sans-serif;
}

.FavouriteShop p,
.review p {
  color: #868388;
}

.FavouriteShop .ParaLead {
  font-size: 20px;
}

.FavouriteShop .ParaLead1 {
  font-size: 20px;
  width: 100%;
}

.FavouriteShop .location {
  color: #cc2529;
  font-size: 20px;
}

.star {
  font-size: 20px;
  color: #ffc107;
}

.FavouriteShop .FavImgBox {
  box-shadow: 0px 5px 16px 0px rgba(167, 167, 167, 0.75);
  /* width: fit-content; */
}

.FavouriteShop .FavImgBox img {
  /*  height: 200px; */
  height: 350px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.FavTab {
  background-color: #c7cbd5;
  color: #141111;
  display: inline-block;
  padding: 10px 25px;
  margin-right: 5px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.FavouriteShop h5 {
  font-size: 24px;
  font-family: "Philosopher", sans-serif;
}

.FavouriteShop .borderbottom {
  border-bottom: 1px solid #c7cbd5;
}

.FavTab.active {
  background-color: #75429c;
  color: #fff;
}

.Service_Provider .search {
  border-radius: 20px;
  background-color: #75429c;
  color: #fff;
}

.Service_Provider .search::placeholder {
  color: #fff;
}

.Service_Provider .searchIcon {
  position: absolute;
  top: 32%;
  right: 20px;
  color: #fff;
  font-weight: 500;
}

.Service_Provider .PrimaryBtn {
  background-color: #75429c;
}

.Service_Provider h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
}

/***** Service Card (Component) *******/
.ServiceCard {
  /* background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 16px 0px rgba(167, 167, 167, 0.75);
 
  margin: 0px auto;
  
  min-height: 500px; */
  color: #3e3e3e;
  background: #fff;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  border-radius: 20px;
  width: 100%;
  height: 100%;
  border: none;
  min-height: 360px;
}

.ServiceCard img.card-img-top {
  /* object-fit: cover;
  object-position: center; */

  border-radius: 20px;
  height: 192px !important;
  width: 100%;
  margin: auto;
  object-fit: cover;
  object-position: top;

}

.ServiceCard .card-body {
  text-align: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 20px;
}

.ServiceCard .long_desc {
  overflow: hidden;
  min-height: 72px;
  margin-bottom: 10px;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.ServiceCard ._serv_title {
  overflow: hidden;
  min-height: 48px;
  margin-bottom: 10px;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.ServiceCard h5 {
  /* font-size: 18px;
  font-weight: bold;
  height: 25px; */

  font-size: 18px;
  /* color: #a024b7; */
  color: #3e3e3e;
  /* color: rgb(232, 74, 86); */
  height: 24px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-family: 'Montserrat Alternates', sans-serif;
  font-weight: 500;
}

.ServiceCard h4 {
  /* font-size: 24px;
  color: #75429c;
  font-weight: 600; */

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #6c7579;
  line-height: 24px;
}

.ServiceCard h4 span {
  font-size: 18px;
  text-decoration: line-through;
  color: #000;
}

.ServiceCard p {
  font-style: normal;
  font-size: 14px;
  font-weight: 300;
  line-height: 30px;
  color: #0a0e17;
}

.ServiceCard .Schedule {
  color: #6a4885;
}

.ServiceCard .Schedule span {
  font-weight: 600;
  color: #3e3e3e;
}

.ServiceCard .card-description {
  overflow: hidden;
  height: 38px;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/***** Service Card Ends (Component) *******/

.ShopService {
  background-image: url("./Default/Assets/Images/Service/ServiceProviderBg.png");
  background-size: cover;
  padding: 140px 0px;
}

.SideDrop {
  background-color: #6e178e;
  padding: 20px;
  color: #fff;
}

.SideDrop button {
  color: #fff;
}

.SideDrop button:hover {
  color: #fff;
}

.SideDrop .dropdown-menu {
  position: static !important;
  float: none;
  transform: none !important;
  background-color: transparent;
  border: 0px;
  display: block;
}

.SideDrop .dropdown-item {
  color: #fff;
}

.SideDrop .dropdown-item:hover,
.SideDrop .dropdown-item:active,
.SideDrop .dropdown-item:focus {
  background-color: inherit;
}

/************************** Auth **************************/

.single_modal {
  backdrop-filter: blur(5px);
  box-shadow: 0 0 15px #c1d3e6;
  position: fixed;
  z-index: 9999;
  background: rgb(0, 0, 0, 25%);
}

.Auth .modal-dialog {
  max-width: 750px;
  margin: 1.4rem auto;
}

.single_cross {
  background-color: #2b2b2b;
  color: #fff;
  font-size: 36px;
  border-radius: 50%;
  padding: 0px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 5px;
  right: -10px;
  cursor: pointer;
}

.Auth .form-control {
  border-radius: 0px;
  border: 1px solid #dbdede;
}

.Auth .form-control::placeholder {
  font-size: 18px;
}

.AuthTab {
  display: inline-block;
  padding: 8px 15px;
  background-color: #eeeeee;
  color: #4f1963;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 0px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.AuthTab.active {
  background-color: #4f1963;
  color: #eeeeee;
}

.AuthTab:hover {
  opacity: 0.8;
}

.Auth .sideBg {
  background-image: url("./Default/Assets/Images/AuthImg.png");
  background-size: cover;
  position: relative;
}

.Auth .sideBg p {
  color: #fff;
  font-size: 14px;
}

.Auth .sideBg .SideContent {
  position: absolute;
  top: 30%;
  width: 90%;
}

.Auth .socialAuth h5 {
  font-weight: bold;
  font-size: 18px;
}

.socialAuth .socialBtn {
  color: #fff;
  font-size: 13px;
  display: inline-block;
  width: 48%;
  border-radius: 4px;
  cursor: pointer;
}

.socialAuth .FBbtn {
  background-color: #33619a;
  box-shadow: 1px 2px 3px 1px #09407c;
  margin-right: 10px;
}

.socialAuth .Googlebtn {
  background: linear-gradient(#fb4f37, #fe472b);
  box-shadow: 1px 2px 3px 1px #d42c11;
}

.socialAuth .socialBtn .Icon {
  padding-right: 5px;
  display: inline-block;
  width: 20%;
  padding: 5px;
  text-align: center;
}

.socialAuth .FBbtn .FbIconBrdr {
  border-right: 1px solid #09407c;
}

.socialAuth .Googlebtn .GoogleIconBrdr {
  border-right: 1px solid #d42c11;
}

.Auth h3 {
  font-size: 36px;
  color: #762158;
  font-family: "Philosopher", sans-serif;
}

.Auth p {
  font-size: 18px;
  color: #504a4a;
}

/* .Auth form {
  max-height: 420px;
  overflow-y: auto;
} */

/************************** Auth end ***************************/
/************************** Edit Profile **************************/

.EditProfile h2 {
  font-family: "Philosopher", sans-serif;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 25px;
  color: #593f6a;
}

.ProfileBox {
  box-shadow: 0px 0px 10px 2px #eeeeee;
  padding: 0px 20px;
}

.ProfileImgCon {
  position: relative;
  width: fit-content;
}

.EditIcon {
  position: absolute;
  /*  right: -10px; */
  right: 0;
  bottom: 0px;
  /*  height: 25px; */
  height: 18px;
}

.ProfileBox h5 {
  font-size: 24px;
  font-weight: bold;
}

.ProfileBox .YourMail {
  color: #085ac1;
  display: inline-block;
  cursor: pointer;
  margin-right: 14px;
  font-size: 18px;
}

.DeleteBtn {
  padding: 2px 16px;
  background-color: #b214b2;
  color: #fff;
  border-radius: 10px;
  font-size: 22px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.DeleteBtn.btn:hover {
  color: #fff;
}

.ProfileBox label {
  color: #7f7d7d;
  font-size: 18px;
  padding-top: 5px;
}

.ProfileBox .form-control,
.Rating .form-control {
  border-radius: 0px;
  border-color: #b214b2;
}

.ProfileBox .PrimaryBtn {
  background-color: #b214b2;
}

.ProfileBox .form-control input::placeholder {
  color: #7f7d7d;
  font-size: 12px;
}

/******* Profile Pic Change Modal ********/

.EditProfilePic .modal-dialog {
  max-width: 650px;
  margin: 4rem auto;
}

/* .EditProfilePic input[type="file"] {
  display: none;
} */
.choose_file {
  color: #fff;
  background-color: #898989;
  padding: 9px 35px;
  font-size: 30px;
  margin-bottom: 0px;
  position: relative;
  cursor: pointer;
  width: 50%;
  border-radius: 5px;
  margin-left: 30px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.choose_file .input_file {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.file-choose h6 {
  color: #898989;
  font-size: 30px;
}

/* .EditProfilePic label {
}
.EditProfilePic .form-group {
  position: relative;
}
.EditProfilePic .form-group::after {
  content: "no file Chosen";
  position: absolute;
  right: 17%;
  top: 10px;
  color: #898989;
  font-size: 30px;
} */
.thumbimg {
  margin-left: 1em;
}

/******* Profile Pic Change Modal Ends ********/

/******* Profile Delete Modal ********/
.ProfDelete .modal-dialog {
  margin: 4rem auto;
}

.PrimaryBtn-outline {
  border: 1px solid #b214b2;
  border-radius: 20px;
  font-weight: 600;
}

.ProfDelete h5 {
  font-size: 30px;
}

.react-tags {
  border: 1px solid #a024b7 !important;
 }

 .css-yk16xz-control {
  border: 1px solid #a024b7 !important;
 }

 .btn-warning {
  white-space: nowrap;
 }

 .btn-primary {
  white-space: nowrap;
 }

 .btn-secondary {
  white-space: nowrap;
 }
 .btn-success {
  white-space: nowrap;
 }

 .table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6;
  white-space: nowrap;
}
/******* Profile Delete Modal Ends ********/
/************************** Edit Profile Ends **************************/

.OrderHistory h2,
.Favourite h2 {
  font-size: 36px;
  font-family: "Philosopher", sans-serif;
  color: #6f597e;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.OrderBox {
  border: 1px solid #5b1c78;
  margin-bottom: 30px;
}

.OrderBox .BoxTop {
  padding: 8px;
  background-color: #a024b7;
  color: #fff;
}

.OrderBox p {
  font-size: 18px;
  font-weight: 600;
}

.OrderBox .OrderTable {
  width: 20%;
  text-align: center;
}

.OrderBox .OrderTable:first-child {
  text-align: left;
}

.OrderTable span {
  font-size: 18px;
  color: #9b9690;
  font-weight: 600;
}

.OrderTable p {
  margin-bottom: 8px;
}

.OrderTable .Status {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.OrderTable .PrimaryBtn {
  background-color: #a024b7;
  border-radius: 10px;
}

.rating h6 {
  font-size: 18px;
  font-weight: bold;
  color: #a024b7;
}

.rating p {
  font-size: 16px;
  font-weight: normal;
  color: #948e88;
}

.rating .react-stars span {
  margin-right: 10px;
}

.Rating .PrimaryBtn {
  font-size: 24px;
}

/******************** Service Details *********************/

.ServiceDetails h2 {
  font-size: 36px;
  font-weight: bold;
  color: #4f1963;
}

.ServiceDetails .Details h4 {
  font-size: 30px;
  font-weight: bold;
}

.Details .Counter {
  border: 1px solid #eeeeee;
}

.Details .CounterBtn {
  background-color: #f9f9f9;
  color: #d6d6d6;
}

.Details .PrimaryBtn {
  background-color: #4f1963;
}

.Details h5 {
  font-size: 24px;
  font-weight: bold;
  color: #a024b7;
}

.Details h5 span {
  font-size: 36px;
  color: #551c5e;
}

.Details .Highlights i {
  color: #32d197;
}

.Highlights p {
  font-size: 18px;
}

.ServiceDetails .DetailsTab {
  display: inline-block;
  padding: 8px 30px;
  font-weight: 600;
  cursor: pointer;
  font-size: 18px;
}

.DetailsTabCon P {
  font-size: 18px;
}

.ServiceDetails .DetailsTab.active {
  background-color: #4f1963;
  color: #fff;
}

.ServiceDetails .borderBottom {
  border-bottom: 1px solid #4f1963;
}

.calender_Booking .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 1em;
  /* background-color: #000; */
}

.calender_Booking .fc .fc-bg-event {
  background: rgb(238 97 97 / 48%);
  opacity: 1;
}

.calender_Booking .fc .fc-bg-event .fc-event-title {
  color: #fff;
}

.calender_Booking .fc-h-event {
  background-color: #a024b7;
  border: 1px solid #a024b7;
  position: relative;
  z-index: -1;
}

.calender_Booking a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end.fc-event-future {
  position: relative;
  z-index: -1;
}

.calender_Booking .fc .fc-button-primary,
.calender_Booking .fc .fc-button-primary:disabled,
.calender_Booking .fc .fc-button-primary:hover,
.calender_Booking .fc .fc-button-primary:active,
.calender_Booking .fc .fc-button-primary:focus {
  background-color: #a024b7;
  border-color: #a024b7;
}

.Slot .Single_close {
  position: absolute;
  top: 10px;
  right: 10px;
}

/********************* Cart ********************/
.proceed_btn {
  background: #a024b7;
  border-radius: 5px;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
}

.proceed_btn:hover {
  color: #fff;
}

.CartAdds {
  border: 1px solid #671981;
  padding: 15px;


  background: #fff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 5px;
}

.CartAdds .CounterBtn {
  border-radius: 50%;
  border: 1px solid #040404;
}

.CartAdds .Amount {
  background-color: #cccccc;
  padding: 3px 30px;
  font-weight: 600;
}

.CartAdds h4 {
  font-size: 20px;
  font-weight: bold;
  color: #0a0e17;
}

.CartAdds h5 {
  font-size: 12px;
  font-weight: bold;
  color: #6b6565;
}

.CartAdds h2 {
  font-size: 22px;
  font-weight: bold;
  color: #a024b7;
}

.CartAdds .fa-trash-alt {
  color: #a229b9;
  font-size: 35px;
  cursor: pointer;
}

.Bill {
  color: #0a0e17;
  padding: 30px 20px;
  background: #fff;
  box-shadow: 0 20px 20px rgb(0 0 0 / 10%);
  border-radius: 5px;
}

.Bill h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #0a0e17;
}

.Bill h5 {
  /* font-size: 26px; */

  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #0a0e17;
}

.Bill h6 {
  /* font-size: 20px; */

  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #0a0e17;
}

.PromoCode input {
  border: 2px solid #a024b7;
  border-radius: 0px;
}

.Cart .PrimaryBtn {
  border-radius: 5px;
  font-size: 24px;
  font-weight: 600;
}

.PromoCode .PrimaryBtn {
  font-size: 18px;
  font-weight: 500;
}

/********************** Cart Ends *********************/
/********************* Contact Us *********************/

.ContactUs h2 {
  font-family: "Philosopher", sans-serif;
  font-size: 48px;
  color: #621f6f;
  padding-bottom: 35px;
}

.ContactUs .AfterEffect {
  position: relative;
}

.ContactUs .AfterEffect::after {
  content: url("./Default/Assets/Images/Blog/BelowCommentSection.png");
  position: absolute;
  bottom: 0px;
  right: 40%;
}

.ContactUs p {
  font-size: 18px;
  color: #605b61;
}

.ContactInfo {
  background-image: url("./Default/Assets/Images/Contact/ContactBg1.png");
  background-size: cover;
}

.ContactBox {
  border: 2px solid #762158;
  padding: 40px;
  text-align: center;
  background-color: #fff;
}

.ContactBox p {
  color: #393939;
  font-size: 18px;
  font-weight: bold;
  min-height: 54px;
}

.ContactUs .FreeEnquiry {
  padding: 100px 0px 10px 0px;
  background-image: url("./Default/Assets/Images/Contact/ContactBg2.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.ContactForm {
  padding: 50px 0;
}

.ContactUs .ContactForm {
  /* background-image: url("./Default/Assets/Images/footerBg.png");
  background-position: 0% 27%;
  background-size: cover;
  padding: 180px 0px 20px 0px;
  margin-top: -40px; */
}

.ContactForm input,
.ContactForm textarea,
.ContactForm select {
  /*  background: transparent; */
  background: rgb(255 255 255 / 35%);
  border: 1px solid #ededed;
  border-radius: 0px;
  color: rgb(0, 0, 0);
}

.ContactForm input::placeholder {
  /* color: #fff; */
  color: #a3aaba;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.ContactForm label {
  font-size: 18px;
  font-weight: bold;
}

.ContactForm .message {
  /* color: #fff; */
  color: #a3aaba;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.ContactForm .PrimaryBtn {
  border: 1px solid #fff;
  font-size: 18px;
  font-weight: bold;
}

h2.innertitle {
  color: #a024b7;
}

.Customer-service.feedback {
  padding: 70px 0
}

/********************* Contact Us End *********************/
/********************* Faq ********************/
.Faq h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 25px;
}

.Faq .ans_text {
  padding: 10px 15px;
  box-shadow: 0px 0px 15px #dadddd;
}

.Faq .question {
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  cursor: pointer;
}

.Faq .question.active {
  color: #8416ac;
}

.Faq .ans_text h6 {
  color: #837787;
  font-size: 18px;
}

/*********************** Faq Ends ***********************/
/********************* Chat ********************/

.chat {
  background-color: #fbfcff;
}

.chat h2 {
  font-family: "Philosopher", sans-serif;
  font-size: 36px;
  font-weight: bold;
  color: #593f6a;
  text-align: center;
  margin-bottom: 30px;
}

.MessageTypeBox {
  border-bottom: 6px solid #b214b2;
  border-radius: 10px 10px 0px 0px;
  background-color: #f8f8f8;
}

.Userlist .messageType {
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.Userlist .messageType.active {
  background-color: #b214b2;
  color: #fff;
  text-align: left;
}

.Userlist .SearchBar {
  display: flex;
  align-items: center;
}

.Userlist .SearchBar input {
  border: 0px;
}

.Userlist .SearchBar input::placeholder {
  color: #a59e9e;
  text-align: right;
  font-size: 18px;
}

.Userlist .imgCon {
  width: 90px;
  height: fit-content;
  border-radius: 50%;
  border: 2px solid #bd3fbe;
}

.Userlist h6 {
  font-size: 17px;
  margin-bottom: 5px;
}

.Userlist p {
  font-size: 14px;
  color: #aba4a4;
}

.Chats {
  max-width: 80%;
}

.Chats h5,
.Chats p {
  font-size: 18px;
}

.MessageDisplay {
  height: 80vh;
  overflow-y: auto;
  /* display: flex;
  flex-direction: column; */
}

/* .MessageDisplay>div {
  overflow-y: auto;
  flex-grow: 1;
} */
.MessageDisplay .reciever .text {
  position: relative;
  background-color: #bd3fbe;
  color: #fff;
  border-radius: 8px;
  min-width: 15rem;
}

.MessageDisplay .reciever .text::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -24px;
  border: 12px solid #bd3fbe;
  border-color: transparent #bd3fbe transparent transparent;
}

.MessageDisplay .chat h5 {
  font-size: 18px;
}

.MessageDisplay .sender {
  direction: rtl;
  margin-left: auto;
  margin-right: 0px;
}

.MessageDisplay .Imgsended {
  max-width: 100%;
  max-height: 100px;
}

.sender .text {
  border: 1px solid #bd3fbe;
  margin-right: 20px;
  border-radius: 8px;
  min-width: 15rem;
  direction: ltr;
}

.MessageDisplay .sender h5 {
  color: #7ab8e2;
}

.MessageDisplay .sender p {
  color: #cec5c5;
}

.MessageWrite {
  display: flex;
  border: 1px solid #bd3fbe;
  align-items: center;
  background-color: #fff;
  padding: 10px;
}

.MessageWrite input[type="text"] {
  border: 0px;
}

.MessageWrite input[type="file"] {
  display: none;
}

.MessageWrite label {
  color: #dfdfdf;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 0px;
  margin-right: 20px;
  margin-left: 20px;
}

.MessageWrite label i {
  font-weight: 400;
}

.MessageWrite .sendBtn {
  background-color: #5bc0de;
  color: #fff;
  border-radius: 50%;
}

/* *****message head****** */
.chat-head .call-icon {
  color: #bd3fbe;
  cursor: pointer;
}

/* ******message head end****** */

/* ******audio call modal****** */
.call_modal {
  background-color: #bd3fbe;
}

.call_modal .call-body h5 {
  color: #ffffff;
  font-size: 25px;
}

.call_modal .call-body p {
  color: #ffffff;
  font-size: 15px;
}

.call_modal .call-body .audio-img {
  width: 100%;
  max-width: 184px;
  height: 184px;
  border-radius: 50%;
}

.call_modal .receive-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: green;
  color: #ffffff;

  box-shadow: 0 0 18px 5px #44eb168f;
}

.call_modal .receive-btn:hover {
  background-color: #ffffff;
  border: 2px solid green;
  color: green;
}

.call_modal .down-part .decline-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: red;
  color: #ffffff;
  box-shadow: 0 0 18px 5px rgb(0 0 0 / 11%);
}

.call_modal .down-part .decline-btn:hover {
  background-color: #ffffff;
  border: 2px solid red;
  color: red;
}

.call_modal .down-part .down-btn {
  width: 60px;
  height: 60px;
  color: #ffffff;
  font-size: 30px;
}

.call_modal .down-part .down-btn:hover {
  color: #000;
}

.call_modal .down-part {
  border-top: 1px solid #ededed;
  padding: 0.8em 0;

  /* margin-top: 1em; */
}

/* **************audio call end**************** */
/* *************video call modal*************** */
.modal-dialog.video-modal {
  position: relative;
  width: 100%;
  /* margin: 0.5rem; */
  pointer-events: none;
  max-width: 716px;
  object-fit: cover;
}

/* video */
._large_video {
  position: relative;
  border-radius: 13px;

  height: clamp(250px, 62%, 370px);
}

._large_video video {
  width: 100%;
  height: 100%;
  max-height: 400px;
  /* border-radius: 8% 8% 0 0; */
  /* margin-top: 1em; */
}

._large_video img {
  width: 100%;
  height: 100%;
  max-height: 500px;
  padding-top: 1em;
}

._video_intro.d-flex {
  position: absolute;
  bottom: 5%;
  left: -5%;
}

._video_intro button {
  font-size: 0.85em;
  border-radius: 8px;
  background: #3a394354;
  color: #ffffff;
}

._large_video .video-img {
  position: absolute;
  top: 1%;
  right: 1%;
  width: 150px;
  height: 150px;
}

._large_video .own-video {
  position: absolute;
  top: 5%;
  right: 1%;
  width: 150px;
  height: 150px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

/* video */
/* *******************video call end********* */
/********************* Chat Ends ********************/

/********************* Footer ************************/
.Footer {
  background-image: url("./Default/Assets/Images/HomeFooterBg.png");
  padding-top: 40px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  color: white;
}

.Footer h3 {
  /* font-family: "Philosopher", sans-serif; */
  font-family: "Roboto", sans-serif;
  /* font-size: 27.74px; */
  font-weight: bold;
  color: #fff;
  font-size: 50px;
  font-weight: 400;
}

.Footer.second {
  /* background-image: url("./Default/Assets/Images/footerBg.png"); */
  padding-top: 40px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  /* margin-top: -80px; */
  background: #1d3557;
}

.footer_store img {
  width: 10rem;
}

.socialBox {
  /* width: 50px;
  height: 50px; */
  border-radius: 50%;
  /* background: #282828; */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  margin-right: 8px;
  cursor: pointer;
}

.socialBox i {
  font-size: 16px;
}

.socialBox i:hover {
  color: #e84a56;
}

.Footer .readMore {
  color: #a024b7;
}

.Footer h4 {
  font-size: 24px;
  /* font-family: "Philosopher", sans-serif; */
  font-family: "Roboto", sans-serif;
  /* color: #fff;
  font-weight: normal; */
  text-align: left;

  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: #fff;
  text-transform: uppercase;
}

.footer_hr {
  width: 4rem;
  height: 4px;
  background-color: #fff;
  opacity: 1;
}

.Footer ul {
  text-align: left;
}

.Footer li {
  color: #fff;
}

.Footer li a:hover {
  /* color: #e84a56; */
  color: #a024b7;
}

.Footer p {
  /* color: #959191; */
  text-align: left;
  color: #fff;
}

.Footer .Brder {
  border-right: 1px solid #383838;
}

.Footer .form-group {
  width: 90%;
  margin: 0px auto;
  position: relative;
}

.Footer .form-control {
  background: transparent;
  border-radius: 0px;
  border: 1px solid #383838;
  color: #fff;
}

.Footer .form-group .send-btn {
  position: absolute;
  right: 0%;
  top: 6%;
  color: #a024b7;
}

/********************* Footer Ends ************************/
/********************* Slot Modal css *************************/
.Slot .modal-body {
  max-height: 360px;
  overflow-y: hidden;
  display: flex;
  flex-wrap: wrap;
}

.Slot .modal-body button.slot_btn {
  flex: 1 142px;
  max-width: 142px;
  margin: 10px auto !important;
}

.Slot .modal-body button.slot_btn:last-child {
  margin-left: 0 !important;
}

.Slot .modal-content:hover .modal-body {
  overflow-y: overlay;
}

/* Width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(215, 221, 226);
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c72fe2;
  transition: all 0.3s;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #861d99;
  transition: all 0.3s;
}

/********************* Slot Modal Ends *************************/
/* ***********artical Details*************** */
/* *************blog details**************** */
.blog-detail-head .blog-date {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;

  color: rgba(79, 25, 99, 0.8);
}

.blog-detail-head .blog_view {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */

  /* Secondary font color */

  color: #a3aaba;
}

.blog-detail-head h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 28px;

  color: #0a0e17;
}

.blog_details_text p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: rgba(10, 14, 23, 0.7);
}

.blog_details_text h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;

  color: rgba(10, 14, 23, 0.7);
}

.blog_details_text span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: rgba(10, 14, 23, 0.7);
}

.popular_box {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(10, 14, 23, 0.1);
  border-radius: 10px;
}

.popular_box .popular_text h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  color: rgba(79, 25, 99, 0.8);
  margin-bottom: 0;
}

.popular_box .popular_text h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #0a0e17;
  margin-bottom: 0;
}

.popular_box .popular_text p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 34px;
  color: #a3aaba;
  cursor: pointer;
  margin-bottom: 0;
}

/******************** Media Query ********************/
/* ********feedback*************** */

.feedback .submit_btn {
  width: 30%;
  background: rgba(79, 25, 99, 0.8);
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  line-height: 1.2;
  color: #ffffff;
  margin-top: 1em;
  height: 50px;
}

/* ********site map*********** */
.Site_map_head {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;

  color: #0a0e17;
}

.Site_map h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 38px;

  color: #0a0e17;
}

.Site_map ul {
  padding: 5px;
}

.Site_map li {
  list-style: disc;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 35px;
  text-decoration-line: underline;
  color: #2251f9;
}

.career_box .card-body {
  padding: 1rem;
}

.career_box .card-title {
  display: -webkit-box;
  min-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.career_box .card-text._desc {
  display: -webkit-box;
  min-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.single_modal .modal-content {
  max-height: 500px;
  overflow-y: auto;
}


/*-------------Seller Dashboard----------------*/
.main-section {
  margin-top: 90px;
}

.main-section .page-content {
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.main-section .page-content::-webkit-scrollbar {
  display: none;
}

.seller_LeftBar {
  background-color: #4f1963;
  width: 24%;
  height: 100vh;
  position: fixed;
  z-index: 1;
}

.seller_LeftBar .menu_section {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.seller_LeftBar .menu_section::-webkit-scrollbar {
  display: none;
}


.page-item:first-child .page-link {
  width: auto;
}

.page-item:last-child .page-link {
  width: auto;
}

.seller_LeftBar h5,
.seller_LeftBar p {
  color: #fff;
}

a.left_menu {
  background-color: #a024b7;
}

.seller_LeftBar .left_menu {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  color: #fff;
  max-width: 100%;
  width: 100%;
}

.seller_LeftBar .left_menu:focus {
  background-color: #fff;
  color: #000;
}

.seller_LeftBar .dropdown-menu {
  background-color: transparent;
  position: relative !important;
  transform: translate3d(0px, 0px, 0px) !important;
  border: none;
}


.seller_LeftBar .left_menu {
  margin-bottom: 13px;
}

.seller_LeftBar .left_menu:focus {
  outline: none;
  box-shadow: none;
}

.seller_LeftBar .left_menu.active {
  color: #333;
  background-color: #ededed;
}

.subscription {
  background-color: #f0f8ff;
}

.subscription h1 {
  font-weight: 700;
  color: #a024b7;
}

.subscription_sec .Subs_card {
  width: 100%;
  height: auto;
  min-height: 240px;
  background: #fff;
  box-shadow: 0 0 15px rgb(2 4 8 / 26%);
  border-radius: 20px;
  border: none;
  border-bottom: 15px solid #a024b7;
}

.subscription_sec p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  height: auto;
  color: #0a0e17;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.subscription_sec .buy_btn {
  border: 2px solid #fff;
  width: 100%;
  max-width: 117px;
  background-color: #a024b7;
}

.subscription_sec .buy_btn,
.subscription_sec .buy_btn:hover {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}

.seller a {
  font-size: 18px;
  color: #495057;
}

.balance_card,
.Seller_Profile .balance_card {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1e5799", endColorstr="#7db9e8", GradientType=0);
  background-color: #4f1963;
  color: #fff;
  block-size: 85px;
  width: 100%;
  max-width: 200px;
  height: 100px;
  margin: 0 auto;
}

.seller .sell_order {
  background: #fff;
  box-shadow: 0 0 18px #e0e0e0;
  border-radius: 15px;
  width: 100%;
  height: auto;
  border: none;
}

.seller .sell_order img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.seller .sell_order h5,
.seller .sell_order h6 {
  font-style: normal;
  font-weight: 700;
  color: #0a0e17;
}

.seller .sell_order h6 {
  font-size: 15px;
}

.seller .sell_order h5 {
  font-size: 18px;
}

.seller .sell_order .acc_btn {
  width: 100%;
  background: rgba(3, 132, 3, .8);
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.2;
  color: #fff;
}

.seller .sell_order .reject_btn {
  width: 100%;
  background: #a024b7;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.2;
  color: #fff;
}

.img-wrap {
  position: relative;
}

.img-wrap .avatar {
  object-fit: cover;
}

.seller_shop .shop_create_btn {
  background-color: #4f1963;
  color: #fff;
  width: 80%;
  height: 50px;
}

.form-control {
  border: 1px solid #a024b7;
}

.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.mdb-datatable {
  overflow-x: scroll;
}

.Add_service label {
  color: #4f1963;
  font-size: 18px;
  font-weight: 600;
}

._borderRed {
  border: 2px solid #a024b7;
}

.Add_service .preview_btn {
  background-color: #33d197;
  color: #fff;
  width: 30%;
  height: 50px;
  line-height: 2.2;
}

.choose_file {
  background: #a3aaba;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  padding: 5px 15px;
  color: #fff;
  width: 120px;
  height: 30px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.choose_file .input_file {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.Add_service .publish_btn {
  background-color: #a024b7;
  color: #fff;
  width: 30%;
  height: 50px;
}

.create_shop .changes_btn {
  background-color: #a024b7;
  color: #fff;
  width: 100%;
  height: 50px;
  font-size: 18px;
}

.create_shop .changes_btn:hover {
  background-color: #000;
}

.seller_LeftBar .left_menu.active {
  color: #333;
  background-color: #ededed;
}

.add_new_serv {
  position: absolute;
  right: 10px;
  top: 15px;
  text-align: center;
  border-radius: 6px;
  padding: 6px 10px;
  font-weight: 700;
  background-color: #a024b7;
  background: #a024b7;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  width: 100%;
  max-width: 181px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}





@media (max-width: 450px) {
  .commentSection h2 {
    line-height: 70px;
    text-align: center;
    position: relative;
  }

  .commentSection h2::after {
    position: absolute;
    top: 35px;
    right: 0;
    left: 0;
    bottom: 0;
  }
}

/* *********site-map end***** */
@media (max-width: 1366) {
  .seller_LeftBar .menu_section {
    height: 300px;
  }
}

@media (min-width: 1366px) {
  .seller_LeftBar .menu_section {
    height: 475px;
  }
}




@media (max-width: 991px) {
  .seller_LeftBar{
    width: 100%;
  }
  .seller_LeftBar{
    position: relative;
  }
  .seller_LeftBar .menu_section {
    width: 100%;
    height: 75%;
  }
  .Navbar {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .AboutUs .countBox {
    width: 100%;
  }

  .Navbar .dropdown-menu {
    left: -25px;
  }

  .secTionHeading.DesignBtm::after {
    left: 41%;
  }

  .secTionHeading.designBtm2::after {
    left: 39%;
  }
}

@media (max-width: 767px) {

  .txtstyle h1 {
    font-size: 20px;
  }

  .about_ban_img {
    position: static;
    margin-bottom: 30px;
  }

  .carousel-control-next-icon {
    display: none;
  }

  .carousel-control-prev-icon {
    display: none;
  }

  .carousel-caption {
    left: 5%;
    top: 15%;
  }

  .carousel-inner {
    height: 40vh;
  }

  .carousel-caption h3 {
    font-size: 14px;
    line-height: 1.3;
  }

  .carousel-caption p {
    font-size: 11px;
    line-height: 1.3;
  }

  .blog .card {
    display: block;
    flex-direction: inherit;
  }

  .blog .Cardtop {
    margin-bottom: 20px;
  }

  .blog .Cardtop img.card-img-top {
    height: 300px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .file-choose {
    display: block !important;
    text-align: center;
    margin-bottom: 20px;
  }

  .file-choose .choose_file {
    width: 100%;
    margin-left: 0;
    text-align: center;
  }

  .file-choose h6 {
    font-size: 26px;
  }

  .site-logo {
    max-width: 200px !important;
  }

  .Footer .Brder {
    border-bottom: 1px solid #383838;
    border-right: 0px;
  }

  .EditProfilePic .form-group::after {
    right: 20%;
  }
}

@media (max-width: 576px) {
  .secTionHeading.designBtm2::after {
    left: 34%;
  }
}

@media (max-width: 567px) {
  .JumbotronHome h1 {
    font-size: 45px;
  }

  .JumboNavBtn {
    min-width: 200px;
  }

  .secTionHeading {
    font-family: "Philosopher", sans-serif;
    font-size: 33px;
  }

  .navbar-brand img {
    width: 190px;
  }

  .FavouriteShop .FavImgBox {
    width: 100%;
  }

  .FavouriteShop .FavImgBox img {
    width: 100%;
  }

  .FavTab {
    display: block;
    margin-bottom: 10px;
  }

  .ContactUs .AfterEffect::after {
    right: 20%;
  }

  .OrderBox .OrderTable {
    width: 100%;
    text-align: left;
    margin-bottom: 8px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .EditProfilePic .form-group::after {
    right: 25%;
    top: 52px;
  }

  .secTionHeading.DesignBtm::after {
    left: 30%;
  }
}

@media (max-width: 420px) {
  .secTionHeading.designBtm2::after {
    left: 26%;
  }

  .socialAuth .socialBtn {
    font-size: 11px;
  }
}

@media (max-width: 370px) {
  .socialAuth .socialBtn {
    width: 46%;
  }

  .socialAuth .socialBtn {
    font-size: 8px;
  }
}

/************************ Checkout **************************/

.badge_color {
  background-color: #bd3fbe;
  color: #fff;
}

.checkout {
  color: #3e3e3e;
}

.checkout .text_muted {
  color: #7e7e7e;
}

.checkout .custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #bd3fbe;
  background-color: #bd3fbe;
}

.checkout input:focus {
  box-shadow: none;
}

/* ********Service_provider******* */
.Provider_banner {
  position: relative;
}

.provider_banner_card {
  /* position: absolute; */
  top: 40%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
}

.provider_banner_card .provider_shop_btn {
  background: #a3aaba;
  border-radius: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.provider_card_img img {
  height: 400px;
  width: 400px;
  max-width: 100%;
  object-fit: cover;
  object-position: top;
}

.provider_card_head p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #0a0e17;
}

.provider_card_head .icon {
  color: #f2c94c;
}

.provider_card_head span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #a3aaba;
}

.provider_card_head h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;

  color: #0a0e17;
}

.provider_card_head .location {
  color: #e84a56;
}

.provider_card_right p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  color: #a3aaba;
}

.provider_card_right h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #0a0e17;
}

.contact span {
  color: #1d3557;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.provider_card_text h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 34px;

  color: #a3aaba;
}

.provider_card_text p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;

  color: #0a0e17;
}

.provider_card_text span {
  font-weight: bold;
}

.provider_card_text h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;

  color: #a3aaba;
  cursor: pointer;
}

.provider_banner_card .card {
  background: #ffffff;
  box-shadow: 0px 34px 88px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: none;
}

.Provider_service_list {
  margin-top: 400px;
}

.Provider_service_list h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height, or 142% */

  color: #0a0e17;
}

.Provider_service_list .Search_bar {
  background: #ededed;
  border-radius: 100px;
  width: 100%;
  max-width: 200px;
}

.Provider_service_list input {
  background: #ededed;
  border-radius: 100px;
}

.Provider_service_list input::placeholder {
  color: rgba(10, 14, 23, 0.3);
}

.provide_tab.active {
  background: #ededed;
  border-radius: 50px;
}

.provide_tab {
  background: #ffffff;

  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 50px;
}

/* ********service provider end****** */
@media screen and (max-width: 767px) {

  .Navbar .navbar-brand,
  .Navbar_OnScroll .navbar-brand {
    font-size: 22px;
  }

  .Navbar {
    /* background-color: rgb(0 0 0 / 75%); */
    background-color: #fff;
  }

  .Footer.second {
    padding-top: 0px;
  }

  .Footer h3 {
    color: #fff;
  }

  .Quotation {
    padding: 150px 0px 10px 0px !important;
  }

  .Quotation .slick-prev,
  .Quotation .slick-next {
    width: 40px;
    height: 50px;
  }

  .ServiceProvider .slick-prev,
  .ServiceProvider .slick-next {
    width: 50px;
    height: 60px;
  }
}

@media print {
  /* @page { size: landscape; } */

  .OrderTable {
    margin-right: 50px !important;
    margin-top: 50px !important;
    margin-left: 50px !important;
  }

  .btn {
    display: none !important;
  }

  .container-fluid {
    font-size: 45px !important;
  }

  .removeInPrint {
    display: none !important;
  }

  .OrderTable img {
    width: 250px !important;
    height: 250px !important;
  }

  .order-print p {
    text-align: center !important;
    position: absolute;
    top: 500px;
    left: 0;
  }

  .react-stars span {
    font-size: 30px !important;
  }

  .order-no {
    justify-content: center;
    margin-top: 40px !important;
  }

  .OrderTable span {
    font-size: 30px !important;
  }



}

.hideButton {
  display: none !important;
}


/*-arrow service list--- */
.ServiceCard .slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  min-width: 19px !important;
  width: 19px;
  height: 19px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  z-index: 1;
  background: rgba(0, 0, 0, .3686274509803922);
}

.ServiceCard .slick-next:before,
.ServiceCard .slick-prev:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  /* color: #070707; */
  color: rgba(220, 181, 87, 1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ServiceCard .slick-prev {
  left: 0;
}

.ServiceCard .slick-next {
  right: 0;
}


/*-----Service Details--------*/
.ban_play {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 20px solid rgb(55, 128, 6);
  border-left: 20px solid rgb(255, 255, 255);
  border-bottom: 10px solid transparent;
  content: '';
  position: absolute;
  right: 50px;
  top: 35%;
  z-index: 999;
}
.link-box{
  align-items: center;
  background: rgb(241,243,244);
  border-radius: 4px;
  color: rgb(95,99,104);
  display: flex;
  padding-left: 0.75rem;
  padding: 0.3rem;
}
.innerlink-box {
  font-family: Roboto,Arial,sans-serif;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: .00625em;
  font-weight: 400;
  color: rgb(32,33,36);
  flex-grow: 1;
}