.sender {
  max-width: 50%;
  width: fit-content;
  

}
.receiver {
  max-width: 50%;
  width: fit-content;

  margin-left: auto;
}

.alert {
  padding: 5px 10px;
}

/*********  hey! cheery here  ***********/

.replyBox .modal-content {
  width: 65%;
  margin: 0 auto;
  max-height: 485px;
  overflow-y: auto;
}
/* my file upload */
.my_fileUpload {
  position: relative;
  margin-bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input.fileUpload {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  /* width: 100%; */
  cursor: pointer;
}

.my_fileUpload > i {
  font-size: 1.5em;
}
button.img_remove {
  position: absolute;
  font-size: 15px;
  color: red;
  top: -8px;
  right: -2px;
}

.upld_img {
  position: relative;
  width: 60px;
}

.chat-widget{
  max-height: 245px;
  overflow-y: auto;
}
.simplebar-placeholder {
  display: none;
  height: auto;
}
.chat-widget .Details h5 {
    font-size: 20px;
    font-weight: 500;
}
div#add-new-task-modal {
    background: #0000003d;
}

.border-bottom.card-body {
    padding: 0.51rem 1rem;
}

.chat-conversation .right .conversation-list .ctext-wrap-content {
    background: #eaeaea;
    margin-bottom: 1rem;
    border-radius: 8px;
    position: relative;
}

.chat-conversation .left .conversation-list .ctext-wrap-content {
  padding: 12px 16px;
  background-color: #5664d2;
  border-radius: 0.25rem;
  color: #fff;
}

.ctext-wrap-content p {
    line-height: 1.2;
    position: relative;
    z-index: 1;
}
.sender .ctext-wrap-content:after {
    content: " ";
    position: absolute;
    width: 20px;
    height: 20px;
    background: transparent;
    left: -10px;
    top: 0;
    border: 10px solid #000;
    border-color: #eaeaea #eaeaea transparent transparent;
}
.receiver .ctext-wrap-content:after {
    content: " ";
    position: absolute;
    width: 20px;
    height: 20px;
    background: transparent;
    right: -10px;
    top: 0;
    z-index: 0;
    border: 10px solid #000;
    border-color: #eaeaea transparent transparent #eaeaea;
}

li.log_time {
  font-size: 75%;
  margin: 1rem auto;
  text-align: center;
  position: relative;
}

li.log_time:after {
  position: absolute;
  content: " ";
  left: 0;
  bottom: 7px;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid #eaeaea;
}

.log_time span:last-child {
  background: #fff4f4;
  position: relative;
  z-index: 1;
  padding: 0 6px;
  border-radius: 4px;
  margin: 0;
}
